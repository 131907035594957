import React from 'react'

import Bondi from '../../images/mealpass/our-partners/bondi.png'
import Glaze from '../../images/mealpass/our-partners/glaze.png'
import JustSalad from '../../images/mealpass/our-partners/just_salad.png'
import Naya from '../../images/mealpass/our-partners/naya.png'
import ShakeShack from '../../images/mealpass/our-partners/shake_shack.png'
import Sweetgreen from '../../images/mealpass/our-partners/sweetgreen.png'
import './partners.less'

const OurPartners = () => {
  return (
    <div className="partners">
      <div className="partners_inner">
        <img src={Sweetgreen} className="partners__logo" width="162" />
        <img src={Naya} className="partners__logo" width="162" />
        <img src={Bondi} className="partners__logo" width="162" />
        <img src={JustSalad} className="partners__logo" width="162" />
        <img src={ShakeShack} className="partners__logo" width="162" />
        <img src={Glaze} className="partners__logo" width="162" />
      </div>
    </div>
  )
}

export default OurPartners
